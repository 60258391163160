<script>
  export default {
    props: {
      data: Array,
    }
  }
</script>

<template>
  <!-- <div BreadCrumb> -->
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(v,k) in data" :key="k" :class="{active:k==data.length-1}"> 
        <router-link v-if="v.link" :to="v.link">{{v.name}}</router-link>
        <a href="#" v-else>{{v.name}}</a>
      </li>
    </ol>
  <!-- </div> -->
</template>